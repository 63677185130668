import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { OktaAuthService } from '@okta/okta-angular';
import { Observable, forkJoin, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public connection = $.hubConnection();
  public rtProxy = null;
  sensorList = [];

  private isHovered = new BehaviorSubject(false);
  isSchematicHovered = this.isHovered.asObservable();

  constructor(
    @Inject('ENV_CONFIG') private envConfig: any,
    public oktaAuth: OktaAuthService
  ) {
    this.connection.disconnected(() => {});    
  }

  handleConnectionRecovery = () => {
    // Attempt to reconnect if not connected
    try {
      if (!this.connection || this.connection.state === $.signalR.connectionState.disconnected) {
        this.startConnection();
      }
    } catch (error) {
      console.error('Error checking connection state: ', error);
    }
  }

  setTokens(tokens:any){
    let accessToken = tokens.accessToken && tokens.accessToken;
    let idToken = tokens.idToken && tokens.idToken;
    if(accessToken && idToken){
      this.oktaAuth.tokenManager.setTokens({ accessToken, idToken });
    }
  }

  setupEventListeners() {
    window.addEventListener('online', this.handleConnectionRecovery);
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.handleConnectionRecovery();
      }
    });
  }

  public getValidTokens(): Observable<{ accessToken: any; idToken: any }> {
    return from(this.oktaAuth.tokenManager.getTokens()).pipe(
      switchMap(tokens => {
        if (tokens && Object.keys(tokens).length > 0) {
          const isAccessTokenExpired = this.oktaAuth.tokenManager.hasExpired(tokens.accessToken);

          if (isAccessTokenExpired) {
            return from(
              this.renewAppTokens(tokens.accessToken, tokens.idToken)
            ).pipe(
              switchMap(([renewedAccessToken, renewedIdToken]) => {
                this.oktaAuth.tokenManager.add('accessToken', renewedAccessToken);
                this.oktaAuth.tokenManager.add('idToken', renewedIdToken);
                
                return [
                  { accessToken: renewedAccessToken, idToken: renewedIdToken }
                ];
              })
            );
          } else {
            // Tokens are valid, return them as they are
            return [{ accessToken: tokens.accessToken, idToken: tokens.idToken }];
          }
        }
        return of(null);
      })
    );
  }

  private renewAppTokens(accessToken: any, idToken: any): Promise<[any, any]> {
    return Promise.all([
      this.oktaAuth.token.renew(accessToken),
      this.oktaAuth.token.renew(idToken)
    ]);
  }

  async startConnection() {
    console.log('signal r start connection');
    const connectionUrl = this.envConfig.api.signalrUrl;
    const accessTokenObj: any = await this.oktaAuth.tokenManager.get(
      'accessToken'
    );
    if (!localStorage.currentUser || !accessTokenObj) {
      return;
    }

    this.connection.url = connectionUrl;

    let isTokenExp = this.oktaAuth.tokenManager.hasExpired(
      accessTokenObj.value
    );
    const idTokenObj: any = await this.oktaAuth.tokenManager.get('idToken');

    if (isTokenExp) {
      console.log(
        'signal r start connection access token expired, renewal in progress'
      );
      return this.renewTokens(accessTokenObj, idTokenObj).pipe(
        switchMap((newTokens) => {
          return this.initiateConnection(newTokens.accessToken);
        })
      );
    } else {
      this.initiateConnection(accessTokenObj.value);
    }
  }

  async initiateConnection(accessToken: any) {
    $.signalR.ajaxDefaults.headers = {
      Authorization: `Bearer ${accessToken}`,
      'Cache-control': 'no-cache, no-store'
    };
    this.connection.qs = { 'access-token': accessToken };
    this.rtProxy = this.connection.createHubProxy('RtsChannel');
    return this.rtProxy;
  }

  private renewTokens(
    accessTokenObj: any,
    idTokenObj: any
  ): Observable<{ accessToken: any; idToken: any }> {
    return from(
      forkJoin([
        this.oktaAuth.token.renew(accessTokenObj.value),
        this.oktaAuth.token.renew(idTokenObj.value)
      ])
    ).pipe(
      switchMap(([renewedAccessToken, renewedIdToken]) => {
        this.oktaAuth.tokenManager.add('accessToken', renewedAccessToken);
        this.oktaAuth.tokenManager.add('idToken', renewedIdToken);
        return of({ accessToken: renewedAccessToken, idToken: renewedIdToken });
      })
    );
  }

  //To emit hover on measurement component to Schematics to update the hover color indicator
  emitIsHovered(liveReadingItem) {
    this.isHovered.next(liveReadingItem);
  }

  async subscribeSignalR(sensorList, retry) {
    if (!retry) {
      this.sensorList = sensorList;
    }

    this.getValidTokens().subscribe({
      next: (tokens) => {
        this.signalR(sensorList, tokens.accessToken.accessToken);
      },
      error: (err) => {
        console.error('Error retrieving tokens:', err);
      }
    });
  }

  async signalR(sensorList, accessToken) {
    this.connection.qs = { 'access-token': accessToken };

    this.connection
      .start({ retryDelay: 5000, maxRetries: 2 })
      .then(() => {
        for (const item of sensorList) {
          this.rtProxy
            .invoke('Join', {
              LogId: item.multiLogId,
              Mnemonic_Alias: item.sensorOriginalName,
              UomProfile: localStorage.userProfile
            })
            .then(() => {
              //console.log('SignalR Connected ---------- ', item.sensorOriginalName);
            });
        }
      })
      .fail((err) => {
        console.log(`signalr error ${err}`);
      });

    this.connection.error((err) => {
      console.log(`signalr error ${err}`);
      this.stopConnection();
      this.startConnection();
    });
  }

  getRtProxy() {
    return this.rtProxy;
  }

  stopConnection() {
    if (this.connection) {
      try {
        if (this.connection.state && this.connection.state === 1) {
          this.connection.stop();
        }
      } catch (err) {
        console.log('SignalR interrupted');
      }
    }
  }
}
